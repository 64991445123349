import React, { useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { IoSearchOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WebService from "../../Services/WebService";
import TableLoader from "../Common/TableLoader/TableLoader";
import AsyncSelect from "react-select/async";
import FridgeIcon from "../../assets/images/fridged.svg";
import UsersIcon from "../../assets/images/users.svg";
import LocationIcon from "../../assets/images/locations.svg";
import FridgecheckIcon from "../../assets/images/fridgecheck.svg";
import FridgeSleepIcon from "../../assets/images/fridgesleep.svg";
import FridgeR from "../../assets/images/fridgeR.svg";
import FridgeG from "../../assets/images/fridgeG.svg";
import SellIcon from "../../assets/images/sell.svg";
import NotificationIcon from "../../assets/images/notification.svg";
import Air from "../../assets/images/air.svg";
import Lungs from "../../assets/images/lungs.svg";
import SettingIcon from "../../assets/images/setting.svg";
import GrowthIcon from "../../assets/images/growth.svg";
import RainSoil from "../../assets/images/rain-soil.svg";
import PlantPot from "../../assets/images/plant-pot.svg";

import { toast } from "react-toastify";
import moment from "moment";
interface Station {
  stationName: string;
  id: string;
  comapanyId: string;
  online: boolean;
  pin: boolean;
}
interface CommandCenterCounts {
  totalActive: number;
  totalClients: number;
  totalLocations: number;
  totalOffline: number;
  totalOnline: number;
  totalSleep: number;
  totalStations: number;
}
const CommandCentreNewFlow = () => {
  const { t } = useTranslation();
  const [commandCenterCounts, setCommandCenterCounts] =
    useState<CommandCenterCounts>({
      totalActive: 0,
      totalClients: 0,
      totalLocations: 0,
      totalOffline: 0,
      totalOnline: 0,
      totalSleep: 0,
      totalStations: 0,
    });
  const [keyword, setKeyword] = useState("");
  const [loader, setLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [stationList, setStationList] = useState<Station[]>([]);
  const [index, setIndex] = useState(0);
  const [sortBy, setSortBy] = useState("stationName");
  const [orderBy, setOrderBy] = useState("ASC");
  const [selectedClient, setSelectedClient] = useState({
    value: "",
    label: "Select Client Name",
  });
  let navigate = useNavigate();

  useEffect(() => {
    getCommandCentreCount();
  }, [page, sortBy, orderBy, keyword, selectedClient]);

  useEffect(() => {
    getCommandCentre(page, selectedClient?.value, "pin");
  }, [selectedClient])

  const getCommandCentreCount = () => {
    setLoader(true);
    return WebService.getAPI({
      action: `command-centre/count?company_id=${selectedClient.value}`,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        setCommandCenterCounts(res?.result);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        return error;
      });
  };
  useEffect(() => {
    let role = localStorage.getItem("role");
    if (role === "MANAGER" || role === "CLIENT") {
      navigate("/access-denied");
    }

    if (index === 0) {
      getCommandCentre(page, selectedClient?.value, "pin");
    }
    const intervalId = setInterval(() => {
      getCommandCentre(page, "", "pin");
    }, 30000);

    return () => clearInterval(intervalId);
  }, [page, sortBy, orderBy, keyword, selectedClient]);

  const getCommandCentre = (page: any, company_id: any, sortByPin?: any) => {
    if (index === 0) {
      setLoader(true);
    }

    const body = {
      page: page,
      order_by: sortByPin ? "DESC" : orderBy,
      sort_by: sortByPin ? sortByPin : sortBy,
      keyword: keyword,
      company_id: selectedClient.value,
    };
    var queryParam = new URLSearchParams(body);
    return WebService.getAPI({
      action: `command-centre/stations?` + queryParam,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        if (index === 0) {
          setLoader(false);
          setIndex(1);
        }

        setStationList(res.list);
        setTotalCount(res.count);
      })
      .catch((error) => {
        setLoader(false);
        return error;
      });
  };
  const handlePinStation = (station_id: any, pin_status: boolean) => {
    const body = {
      stationId: station_id,
      pin: pin_status,
    };

    return WebService.putAPI({
      action: `station/pin`,
      body: body,
      isShowError: true,
    })
      .then((res: any) => {
        toast.success(res?.message);
      })
      .catch((error) => {
        setLoader(false);
        return error;
      });
  };
  const handleSwitch = (stationId: any, status: any) => {
    let obj = {
      stationId: stationId,
      online: !status,
    };
    return WebService.putAPI({
      action: `station/isonline`,
      body: obj,
      isShowError: true,
    })
      .then((res: any) => {
        toast.success(res?.message);
        // let tempStationList = [...stationList];
        // tempStationList[index].online = !status;
        // setStationList(tempStationList);

        setStationList((prevStationList) =>
          prevStationList.map((station: any, i) =>
            station?.id === stationId
              ? { ...station, online: !station.online }
              : station
          )
        );
      })
      .catch((error) => {
        toast.error(error.data.message);
      });
  };
  const clientPromiseOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      const body = {
        page: page.toString(),
        order_by: orderBy,
        sot_by: sortBy,
        keyword: keyword,
      };
      var queryParam = new URLSearchParams(body);
      return WebService.getAPI({
        action: `companies?` + queryParam,
        // action: `companies?=${inputValue}`,
        body: null,
        isShowError: true,
      })
        .then((res: any) => {
          if (res.list.length > 0) {
            var updatedOptions = res.list.map((companyName: any) => ({
              value: companyName.id,
              label: companyName.name,
            }));
            updatedOptions.unshift({
              value: "",
              label: "All",
            });
            resolve(updatedOptions);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });

  const onClientSelection = (selectedOption: any) => {
    console.log(selectedOption);
    setSelectedClient(selectedOption);
  };
  return (
    <>
      <Row>
        <Col>
          <div className="justify-content-between align items-center mb-3">
            <h5 className="mt-2 mr-3">
              <b>Manage Command Centre</b>
            </h5>
          </div>
        </Col>
        <Col>
          <div className="form-group">
            <AsyncSelect
              id="select_client"
              value={selectedClient}
              cacheOptions
              defaultOptions
              onChange={onClientSelection}
              loadOptions={clientPromiseOptions}
              placeholder={"Select Client"}
            />
          </div>
        </Col>
        <Col md={5} className="d-flex justify-content-end gap-3 mb-3">
          <InputGroup className="table-search-box border">
            <Form.Control
              placeholder={t("Search_for_anything")}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  setKeyword(e.currentTarget.value);
                }
              }}
            />
            <InputGroup.Text id="basic-addon1">
              <IoSearchOutline className="icon" />
            </InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>

      <div className="commandCentre-page">
        {" "}
        {/* table-card card-boxes */}
        <Row className="g-3">
          <Col lg={8} sm={8}>
            <Row className="g-3 mb-3">
              <Col lg={3} sm={3}>
                <div className="white-card-box">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col me-1">
                      <img src={FridgeIcon} alt="" className="margin-mins" />
                      <span className="main-title">
                        {commandCenterCounts?.totalStations}
                      </span>
                      <br />
                      <span className="sub-title">Total JBS</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} sm={3}>
                <div className="white-card-box">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col me-1">
                      <img src={UsersIcon} alt="" className="margin-mins" />
                      <span className="main-title">
                        {commandCenterCounts?.totalClients}
                      </span>
                      <br />
                      <span className="sub-title">Clients</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} sm={3}>
                <div className="white-card-box">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col me-1">
                      <img src={LocationIcon} alt="" className="margin-mins" />
                      <span className="main-title">
                        {commandCenterCounts?.totalLocations}
                      </span>
                      <br />
                      <span className="sub-title">Locations</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} sm={3}>
                <div className="white-card-box">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col me-1">
                      <img
                        src={FridgecheckIcon}
                        alt=""
                        className="margin-mins"
                      />
                      <span className="main-title">
                        {commandCenterCounts?.totalActive}
                      </span>
                      <br />
                      <span className="sub-title">Active</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} sm={3}>
                <div className="white-card-box">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col me-1">
                      <img
                        src={FridgeSleepIcon}
                        alt=""
                        className="margin-mins"
                      />
                      <span className="main-title">
                        {commandCenterCounts?.totalSleep}
                      </span>
                      <br />
                      <span className="sub-title">Sleep</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} sm={3}>
                <div className="white-card-box greenBorder">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col me-1">
                      <img src={FridgeG} alt="" className="margin-mins" />
                      <span className="main-title">
                        {commandCenterCounts?.totalOnline}
                      </span>
                      <br />
                      <span className="sub-title">Online</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} sm={3}>
                <div className="white-card-box redBorder">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col me-1">
                      <img src={FridgeR} alt="" className="margin-mins" />
                      <span className="main-title">
                        {commandCenterCounts?.totalOffline}
                      </span>
                      <br />
                      <span className="sub-title">Offline</span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="g-3">
              {stationList &&
                stationList?.length > 0 &&
                stationList?.map((data: any, index: any) => (
                  <Col lg={4} sm={4} key={index}>
                    <div className="white-card-box position-relative px-0 pb-0">
                      <div className="d-flex align-items-center justify-content-start px-3">
                        <div className="me-2">
                          <img src={SellIcon} alt="" sizes="" />
                        </div>
                        <div className="me-3">
                          <span className="label-title">
                            {data?.companyName} {data?.machineId}
                          </span>
                          <br />
                          <span className="cityname">
                            {data?.stationLocation}
                          </span>
                        </div>
                        <div className="divider">
                          <span className="cityname">AQI</span>
                          <br />
                          <span className="excellent">Excellent</span>
                        </div>
                        <div className="position-absolute img-position">
                          <img src={NotificationIcon} alt="" sizes="" />
                          <span className="count">1</span>
                        </div>
                      </div>
                      <div className="borders-top">&nbsp;</div>
                      <div className="d-flex align-items-center justify-content-center">
                        <Row className="h-100">
                          <Col lg={3} className="my-auto px-0">
                            <img
                              src={Air}
                              alt=""
                              sizes="25"
                              className="imgWidth"
                            />
                          </Col>
                          <Col lg={9} className="my-auto">
                            <div className="width80">
                              <span className="label-title-green">
                                {data?.db}
                              </span>
                              <small className="small-text">µg/m³</small>
                              <br />
                              <span className="cityname">DP</span>
                            </div>
                          </Col>
                        </Row>
                        <div className="borderCenter">&nbsp;</div>
                        <Row className="h-100">
                          <Col lg={3} className="my-auto px-0">
                            <img
                              src={Lungs}
                              alt=""
                              sizes="25"
                              className="imgWidth"
                            />
                          </Col>
                          <Col lg={9} className="my-auto">
                            <div className="width80">
                              <span className="label-title-green">
                                {data?.pm25}
                              </span>
                              <small className="small-text">µg/m³</small>
                              <br />
                              <span className="cityname">PM 2.5</span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="borders-btm">&nbsp;</div>
                      <div className="d-flex align-items-center justify-content-center">
                        <Row className="h-100">
                          <Col lg={3} className="my-auto px-0">
                            <img
                              src={RainSoil}
                              alt=""
                              sizes="25"
                              className="imgWidth"
                            />
                          </Col>
                          <Col lg={9} className="my-auto">
                            <div className="width80">
                              <span className="label-title-green">
                                {data?.soilWaterLevel}
                              </span>
                              <small className="small-text">%</small>
                              <br />
                              <span className="cityname">Water</span>
                            </div>
                          </Col>
                        </Row>
                        <div className="borderCenter">&nbsp;</div>
                        <Row className="h-100">
                          <Col lg={3} className="my-auto px-0">
                            <img
                              src={PlantPot}
                              alt=""
                              sizes="25"
                              className="imgWidth"
                            />
                          </Col>
                          <Col lg={9} className="my-auto">
                            <div className="width80">
                              <span className="label-title-green">
                                {data?.tankWaterLevel}
                              </span>
                              <small className="small-text">%</small>
                              <br />
                              <span className="cityname">Soil Water</span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="gery-bg">
                        <div className="d-flex align-items-center justify-content-between py-2 px-3">
                          <div className="label-title">
                            NSS :{" "}
                            {data?.nextServiceDate !== null
                              ? moment(data?.nextServiceDate).format(
                                "DD-MM-YYYY"
                              )
                              : ""}
                          </div>
                          <div className="cityname">
                            <span>
                              {data?.completedDate !== null
                                ? moment(data?.completedDate).format(
                                  "DD-MM-YYYY"
                                )
                                : ""}
                            </span>
                            <br />
                            <span>LG- {data?.leafGuardianName}</span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-2 px-3">
                        <div>
                          <img src={SettingIcon} alt="" sizes="25" />
                        </div>
                        <div>
                          <img src={GrowthIcon} alt="" sizes="25"
                            onClick={() =>
                              navigate(
                                `/command-centre-new-flow-details/${data?.id}/${data?.comapanyId}`, { state: data }
                              )
                            }
                          />
                        </div>
                        <div>
                          <div className="form-check form-switch form-switch-md mb-0">
                            <input
                              className="form-check-input mt-0 cursor-pointer"
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                              checked={data?.online}
                              onChange={() => {
                                handleSwitch(data?.id, data?.online);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexSwitchCheckDefault"
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          </Col>
          <Col lg={4} sm={4}>
            <div className="white-card-box">
              <h5 className="h5title text-center mb-3">Extra Care Unit</h5>
              <Row className="g-3">
                <Col lg={12} sm={12}>
                  <div className="white-card-box position-relative px-0 pb-0">
                    <div className="d-flex align-items-center justify-content-start px-3">
                      <div className="me-2">
                        <img src={SellIcon} alt="" sizes="" />
                      </div>
                      <div className="me-3">
                        <span className="label-title">JBS 001</span>
                        <br />
                        <span className="cityname">Channai</span>
                      </div>
                      <div className="divider">
                        <span className="cityname">AQI</span>
                        <br />
                        <span className="excellent">Excellent</span>
                      </div>
                      <div className="position-absolute img-position">
                        <img src={NotificationIcon} alt="" sizes="" />
                        <span className="count">1</span>
                      </div>
                    </div>
                    <div className="borders-top">&nbsp;</div>
                    <div className="d-flex align-items-center justify-content-center">
                      <Row className="h-100">
                        <Col lg={3} className="my-auto px-0">
                          <img
                            src={Air}
                            alt=""
                            sizes="25"
                            className="imgWidth"
                          />
                        </Col>
                        <Col lg={9} className="my-auto">
                          <div className="width80">
                            <span className="label-title-green">57</span>
                            <small className="small-text">µg/m³</small>
                            <br />
                            <span className="cityname">DP</span>
                          </div>
                        </Col>
                      </Row>
                      <div className="borderCenter">&nbsp;</div>
                      <Row className="h-100">
                        <Col lg={3} className="my-auto px-0">
                          <img
                            src={Lungs}
                            alt=""
                            sizes="25"
                            className="imgWidth"
                          />
                        </Col>
                        <Col lg={9} className="my-auto">
                          <div className="width80">
                            <span className="label-title-green">19</span>
                            <small className="small-text">µg/m³</small>
                            <br />
                            <span className="cityname">PM 2.5</span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="borders-btm">&nbsp;</div>
                    <div className="d-flex align-items-center justify-content-center">
                      <Row className="h-100">
                        <Col lg={3} className="my-auto px-0">
                          <img
                            src={RainSoil}
                            alt=""
                            sizes="25"
                            className="imgWidth"
                          />
                        </Col>
                        <Col lg={9} className="my-auto">
                          <div className="width80">
                            <span className="label-title-green">45 </span>
                            <small className="small-text">%</small>
                            <br />
                            <span className="cityname">Water</span>
                          </div>
                        </Col>
                      </Row>
                      <div className="borderCenter">&nbsp;</div>
                      <Row className="h-100">
                        <Col lg={3} className="my-auto px-0">
                          <img
                            src={PlantPot}
                            alt=""
                            sizes="25"
                            className="imgWidth"
                          />
                        </Col>
                        <Col lg={9} className="my-auto">
                          <div className="width80">
                            <span className="label-title-green">45</span>
                            <small className="small-text">%</small>
                            <br />
                            <span className="cityname">Soil Water</span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="gery-bg">
                      <div className="d-flex align-items-center justify-content-between py-2 px-3">
                        <div className="label-title">NSS : 09-04-2023</div>
                        <div className="cityname">
                          <span>05-04-2023</span>
                          <br />
                          <span>LG- Neeraj</span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between py-2 px-3">
                      <div>
                        <img src={SettingIcon} alt="" sizes="25" />
                      </div>
                      <div>
                        <img src={GrowthIcon} alt="" sizes="25"
                        // onClick={() =>
                        //   navigate(
                        //     `/command-centre-new-flow-details/${data?.id}/${data?.comapanyId}`,{state:data     }
                        //   )
                        // }
                        />
                      </div>
                      <div>
                        <div className="form-check form-switch form-switch-md mb-0">
                          <input
                            className="form-check-input mt-0"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckDefault"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={12} sm={12}>
                  <div className="white-card-box position-relative px-0 pb-0">
                    <div className="d-flex align-items-center justify-content-start px-3">
                      <div className="me-2">
                        <img src={SellIcon} alt="" sizes="" />
                      </div>
                      <div className="me-3">
                        <span className="label-title">JBS 001</span>
                        <br />
                        <span className="cityname">Channai</span>
                      </div>
                      <div className="divider">
                        <span className="cityname">AQI</span>
                        <br />
                        <span className="excellent">Excellent</span>
                      </div>
                      <div className="position-absolute img-position">
                        <img src={NotificationIcon} alt="" sizes="" />
                        <span className="count">1</span>
                      </div>
                    </div>
                    <div className="borders-top">&nbsp;</div>
                    <div className="d-flex align-items-center justify-content-center">
                      <Row className="h-100">
                        <Col lg={3} className="my-auto px-0">
                          <img
                            src={Air}
                            alt=""
                            sizes="25"
                            className="imgWidth"
                          />
                        </Col>
                        <Col lg={9} className="my-auto">
                          <div className="width80">
                            <span className="label-title-green">57</span>
                            <small className="small-text">µg/m³</small>
                            <br />
                            <span className="cityname">DP</span>
                          </div>
                        </Col>
                      </Row>
                      <div className="borderCenter">&nbsp;</div>
                      <Row className="h-100">
                        <Col lg={3} className="my-auto px-0">
                          <img
                            src={Lungs}
                            alt=""
                            sizes="25"
                            className="imgWidth"
                          />
                        </Col>
                        <Col lg={9} className="my-auto">
                          <div className="width80">
                            <span className="label-title-green">19</span>
                            <small className="small-text">µg/m³</small>
                            <br />
                            <span className="cityname">PM 2.5</span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="borders-btm">&nbsp;</div>
                    <div className="d-flex align-items-center justify-content-center">
                      <Row className="h-100">
                        <Col lg={3} className="my-auto px-0">
                          <img
                            src={RainSoil}
                            alt=""
                            sizes="25"
                            className="imgWidth"
                          />
                        </Col>
                        <Col lg={9} className="my-auto">
                          <div className="width80">
                            <span className="label-title-green">45 </span>
                            <small className="small-text">%</small>
                            <br />
                            <span className="cityname">Water</span>
                          </div>
                        </Col>
                      </Row>
                      <div className="borderCenter">&nbsp;</div>
                      <Row className="h-100">
                        <Col lg={3} className="my-auto px-0">
                          <img
                            src={PlantPot}
                            alt=""
                            sizes="25"
                            className="imgWidth"
                          />
                        </Col>
                        <Col lg={9} className="my-auto">
                          <div className="width80">
                            <span className="label-title-green">45</span>
                            <small className="small-text">%</small>
                            <br />
                            <span className="cityname">Soil Water</span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="gery-bg">
                      <div className="d-flex align-items-center justify-content-between py-2 px-3">
                        <div className="label-title">NSS : 09-04-2023</div>
                        <div className="cityname">
                          <span>05-04-2023</span>
                          <br />
                          <span>LG- Neeraj</span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between py-2 px-3">
                      <div>
                        <img src={SettingIcon} alt="" sizes="25" />
                      </div>
                      <div>
                        <img src={GrowthIcon} alt="" sizes="25" />
                      </div>
                      <div>
                        <div className="form-check form-switch form-switch-md mb-0">
                          <input
                            className="form-check-input mt-0"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckDefault"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default CommandCentreNewFlow;
